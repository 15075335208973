import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Divider, Button, Typography, Avatar, Menu, MenuItem } from "@mui/material";
import syngentaLogo from "../app/black1000.png";
import { useCookies } from "react-cookie";
// import LandingPageToggleSwitch from "./LandingPageToggleSwitch";

const useStyles = makeStyles(() => ({
  toolBar: {
    display: "flex",
    alignItems: "center",
    float: "right",
    fontSize: ".875rem",
    marginTop: "2px"
  }
}));

function Appbar() {
  const classes = useStyles();
  const [cookies] = useCookies(["userEmail"]);
  const name = cookies?.userEmail;
  const pro = name?.split(".");
  let navigate = useNavigate();
  const [profileClick, setProfileClick] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const routeChange = () => {
    let path = "/home/logout";
    navigate(path);
  };

  const homeRoute = () => {
    let path = "/home";
    navigate(path);
  };

  return (
    <>
      <Grid item xs={12}>
        <img
          src={syngentaLogo}
          onClick={homeRoute}
          alt="syngenta"
          style={{
            width: 130,
            cursor: "pointer"
          }}
        />
        <span className={classes.toolBar}>
        {/* <LandingPageToggleSwitch /> */}
        <Button
          onClick={() => {
            setProfileClick(true);
          }}
          >
          <Avatar
            sx={{ bgcolor: "#006D82", width: 30, height: 30, fontSize: 13 }}
            aria-label="recipe">
            {pro && pro.length > 1 ? pro[0].charAt(0) + pro[1].charAt(0) : pro[0].charAt(0)}
          </Avatar>
        </Button>
        </span>
        <Menu
          open={profileClick}
          anchorEl={anchorEl}
          onClose={() => {
            setProfileClick(false); setAnchorEl(null);
          }}
          onClick={() => {
            setProfileClick(false);
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              marginTop: "40px"
            }
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}>
          <MenuItem>
            <Typography sx={{ fontSize: ".875rem" }}>{name}</Typography>
          </MenuItem>
          <MenuItem sx={{ paddingLeft: "5px" }}>
            <Button
              sx={{
                color: "green",
                fontSize: ".875rem",
                textTransform: "capitalize"
              }}
              onClick={routeChange}>
              Logout
            </Button>
          </MenuItem>
        </Menu>
      </Grid>
      <Divider
        variant="middle"
        sx={{
          width: "100%",
          margin: "5px 0px 20px 0px"
        }}
      />
    </>
  );
}
export default Appbar;
