import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Avatar,
  Tooltip,
  MenuItem,
  Typography,
  Menu,
  Button
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { propTypes } from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";
import MobileIcon from "../assests/Styles/assets/mobile1.png";
// import LandingPageToggleSwitch from "./LandingPageToggleSwitch";
const useStyles = makeStyles(() => ({
  root: {
    color: "hsla(0,0%,100%,.5)",
    fontSize: ".875rem",
    display: "flex",
    padding: "0px 10px"
  }
}));

function MapIcon(props) {
  return (
    <SvgIcon {...props} viewBox="1 0 6 9">
      <path d="m0 0v8h8v-2.38a.5.5 0 0 0 0-.22v-5.41h-8zm1 1h6v4h-1.5a.5.5 0 0 0 -.09 0 .5.5 0 1 0 .09 1h1.5v1h-6zm2.5 1c-.83 0-1.5.67-1.5 1.5 0 1 1.5 2.5 1.5 2.5s1.5-1.5 1.5-2.5c0-.83-.67-1.5-1.5-1.5zm0 1c.28 0 .5.22.5.5s-.22.5-.5.5-.5-.22-.5-.5.22-.5.5-.5z" />
    </SvgIcon>
  );
}
function ListIcon(props) {
  return (
    <SvgIcon {...props} viewBox="1 0 6 9">
      <path d="m.5 0c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm1.5 0v1h6v-1zm-1.5 2c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm1.5 0v1h6v-1zm-1.5 2c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm1.5 0v1h6v-1zm-1.5 2c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm1.5 0v1h6v-1z" />
    </SvgIcon>
  );
}
function ProjectIcon(props) {
  return (
    <SvgIcon {...props} viewBox="1 0 6 9">
      <path d="m0 0v7h1v-7zm7 0v7h1v-7zm-5 1v1h2v-1zm1 2v1h2v-1zm1 2v1h2v-1z" />
    </SvgIcon>
  );
}

function Navbar(selectedYear) {
  const classes = useStyles();
  const username = sessionStorage.getItem("userName");
  let navigate = useNavigate();
  const [toolTip, setTooltip] = useState(false);
  const [profileClick, setProfileClick] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const pro = username?.split(".");

  const routeChange = () => {
    setTooltip(!toolTip);
    let path = "/home/logout";
    navigate(path);
  };
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery("(max-width:600px)");
  const onlyMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const siteUrl = `/home/sites/${selectedYear.selectedYear}`;
  const protocolUrl = `/home/protocol/${selectedYear.selectedYear}`;
  const trailUrl = `/home/trials/${selectedYear.selectedYear}`;

  const handleProfileClose = () => {
    setProfileClick(false);
    setAnchorEl(null);
  }
  const paddingLeft = onlySmallScreen ? "0px" : "16px";
  const paddingRight = onlyMediumScreen ? (onlySmallScreen ? "0px" : "8px") : "16px";

  return (
    <AppBar position="fixed">
      <Toolbar
        data-testid="navbar-toolbar"
        sx={{
          backgroundColor: "black",
          color: "hsla(0,0%,100%,.5)",
          fontSize: ".875rem",
          paddingLeft: onlySmallScreen ? "5px" : "16px",
          paddingRight: onlySmallScreen ? "5px" : "16px"
        }}>
        {!selectedYear.loading && (
          <>
            <List
              className={classes.root}
              orientation="horizontal"
              disablePadding={true}
              sx={{
                width: onlyMediumScreen ? "100%" : "",
                paddingLeft: paddingLeft,
                paddingRight: paddingRight
              }}>
              <ListItem
                disablePadding
                sx={{ display: "flex", width: "45px" }}
                component={Link}
                to="/">
                <ListItemButton sx={{ display: "block", paddingLeft: "0px", paddingRight: "20px" }}>
                  <img src={MobileIcon} alt="syngenta" href="/" />
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                alignItems="center"
                sx={{ width: "75px" }}
                component={Link}
                to={siteUrl}>
                <ListItemButton
                  sx={{
                    display: "block",
                    paddingLeft: onlySmallScreen ? "8px" : "16px",
                    paddingRight: onlySmallScreen ? "8px" : "16px"
                  }}>
                  <ListItemIcon
                    className={classes.icon}
                    style={{
                      color: location.pathname.indexOf("sites") > -1 ? "white" : null,
                      minWidth: "70px"
                    }}
                    sx={{ color: "hsla(0,0%,100%,.5)", justifyContent: "center" }}>
                    <MapIcon style={{ marginTop: "5px" }} />
                    <ListItemText
                      primary="Sites"
                      sx={{
                        marginTop: onlySmallScreen ? "30px" : "5px",
                        marginLeft: onlySmallScreen ? "-25px" : "5px"
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <ListItem
                disablePadding
                alignItems="center"
                sx={{ width: "80px" }}
                component={Link}
                to={trailUrl}>
                <ListItemButton
                  sx={{
                    display: "block",
                    paddingLeft: onlySmallScreen ? "8px" : "16px",
                    paddingRight: onlySmallScreen ? "8px" : "16px"
                  }}>
                  <ListItemIcon
                    className={classes.icon}
                    style={{
                      color: location.pathname.indexOf("trials") > -1 ? "white" : null,
                      minWidth: "70px"
                    }}
                    sx={{ color: "hsla(0,0%,100%,.5)", justifyContent: "center" }}>
                    <ListIcon style={{ marginTop: "5px" }} />

                    <ListItemText
                      primary="Trials"
                      sx={{
                        marginTop: onlySmallScreen ? "30px" : "5px",
                        marginLeft: onlySmallScreen ? "-25px" : "5px"
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                alignItems="center"
                sx={{ width: onlyMediumScreen ? "auto" : "80px" }}
                component={Link}
                to={protocolUrl}>
                <ListItemButton sx={{ display: "block" }}>
                  <ListItemIcon
                    className={classes.icon}
                    style={{
                      color: location.pathname.indexOf("protocol") > -1 ? "white" : null,
                      minWidth: "100px"
                    }}
                    sx={{ color: "hsla(0,0%,100%,.5)", justifyContent: "center" }}>
                    <ProjectIcon style={{ marginTop: "5px" }} />

                    <ListItemText
                      primary="Protocols"
                      sx={{
                        marginTop: onlySmallScreen ? "30px" : "5px",
                        marginLeft: onlySmallScreen ? "-25px" : "5px"
                      }}
                    />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>

              <ListItem
                disablePadding
                alignItems="center"
                sx={{
                  justifyContent: onlyMediumScreen ? "flex-end" : "flex-start",
                  width: onlyMediumScreen ? "100%" : "50px"
                }}>
                <ListItemButton
                  sx={{
                    width: onlySmallScreen ? "auto" : "50px",
                    justifyContent: onlyMediumScreen ? "flex-end" : "flex-start",
                    paddingLeft: onlyMediumScreen ? "0px" : "16px",
                    paddingRight: onlyMediumScreen ? "0px" : "16px"
                  }}>
                  <ListItemIcon
                    edge="end"
                    onClick={() => setProfileClick(true)}
                    className={classes.icon}
                    sx={{
                      color: "hsla(0,0%,100%,.5)",
                      justifyContent: onlyMediumScreen ? "flex-end" : "center",
                      paddingLeft: onlyMediumScreen ? "0px" : "20px",
                      minWidth: onlySmallScreen ? "100%" : "56px"
                    }}>
                    <Tooltip
                      title={pro}
                      sx={{ color: "red" }}
                      disableFocusListener={true}
                      disableHoverListener={true}
                      open={toolTip}
                      placement="bottom-left"
                      enterTouchDelay={0}>
                      <Avatar
                        sx={{ bgcolor: "#006D82", width: 30, height: 30, fontSize: 13 }}
                        aria-label="recipe">
                        {pro && pro.length > 1
                          ? pro?.[0].charAt(0) + pro?.[1]?.charAt(0)
                          : pro?.[0].charAt(0)}
                      </Avatar>
                    </Tooltip>
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>

            <Menu
              open={profileClick}
              anchorEl={anchorEl}
              onClose={handleProfileClose}
              onClick={() => {
                setProfileClick(false);
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  marginTop: onlySmallScreen? "57px":"48px"
                }
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}>
              <MenuItem>
                <Typography sx={{ fontSize: ".875rem" }}>{username}</Typography>
              </MenuItem>
              {/* <MenuItem>
                <LandingPageToggleSwitch />
              </MenuItem> */}
              <MenuItem sx={{ paddingLeft: "5px" }}>
                <Button
                  sx={{
                    color: "green",
                    fontSize: ".875rem",
                    textTransform: "capitalize"
                  }}
                  onClick={routeChange}>
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;

Navbar.propTypes = {
  selectedYear: propTypes.func,
  yearList: propTypes.array,
  loading: propTypes.bool
};
